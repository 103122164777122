import React from "react";
import { ContactUI } from "./ContactUI";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

function Contact() {
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      const { name, company, email, phone, details } = formValue;
      console.log(
        name + ", " + company + ", " + email + ", " + phone + ", " + details
      );
      const requestBodyEmail = {
        name,
        company,
        email,
        phone,
        details,
      };
      emailjs
        .send(
          "service_l7gq6ro",
          "template_usq7gxe",
          requestBodyEmail,
          "B9H1OZ45zQKsUdGL1"
        )
        .then((result) => {
          if (result.status === 200) {
            alert("Mensaje enviado con éxito");
          } else {
            alert("Error, " + result.status + " - " + result.text);
          }
        });
    },
  });

  return <ContactUI formik={formik} />;
}

function initialValues() {
  return {
    name: "",
    company: "",
    email: "",
    phone: "",
  };
}

function validationSchema() {
  return {
    name: Yup.string().required("El nombre es obligatorio"),
    company: Yup.string().required("La compañía es obligatoria"),
    email: Yup.string("Ingrese un correo válido")
      .email("Ingrese un correo válido")
      .required("El correo electrónico es es obligatorio"),
    phone: Yup.number("Ingrese un número válido")
      .integer("Ingrese un número válido")
      .positive("Ingrese un número válido")
      .required("El teléfono es obligatorio"),
  };
}
export default Contact;
