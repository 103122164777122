import React from "react";
import { useNavigate } from "react-router-dom";
import { GsoftUI } from "./GsoftUI";

function Gsoft() {
  const navigate = useNavigate()
  return <GsoftUI navigate={navigate}/>;
}

export default Gsoft;
