import React from "react";

import { BsInstagram } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ImFacebook } from "react-icons/im";
import { URL_DOMAIN } from "../../../utils/constants";

function Footer() {
  return (
    <div className="footerContainer pb-5 text-white">
      <div className="row text-center">
        <div className="col-4">
          <Link className="nav-link link text-white" to={"/About"}>
            NUESTRA COMPAÑÍA
          </Link>
        </div>
        <div className="col-4">
          <Link className="nav-link link text-white" to={"/"}>
            LÍNEAS DE NEGOCIO
          </Link>
        </div>
        <div className="col-4">
          <Link className="nav-link link text-white" to={"/contact"}>
            CONTACTO
          </Link>
        </div>
        <div className="col-3 d-none">
          <Link className="nav-link link text-white" to={"/blog"}>
            BLOG
          </Link>
        </div>
      </div>

      <hr />

      <div className="row text-center">
        <div className="col-2">
          <img
            src={URL_DOMAIN + "assets/images/gec/logo-gec.png"}
            alt="logo"
            className="w-100"
          />
        </div>
        <div className="col-1 offset-7">
          <a
            href="https://instagram.com/gec_sol?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsInstagram size="2rem" color="white"/>
          </a>
        </div>
        <div className="col-1">
          <a
            href="https://www.facebook.com/GECSOLSAS?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImFacebook size="2rem" color="white"/>
          </a>
        </div>
        <div className="col-1">
          <a
            href="https://www.linkedin.com/company/gecsol/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillLinkedin size="2.2rem" color="white" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
