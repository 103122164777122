import "./Home.css";
import {URL_DOMAIN } from "../../utils/constants";
import { AnimationOnScroll } from "react-animation-on-scroll";

function HomeUI(props) {
  const { navigate } = props;
  return (
    <>
      <div className="containerPage w-100 michroma">
        <div className="row w-100 px-0 gx-0">
          <img
            src={URL_DOMAIN + "assets/images/gec/img-principal-gec.png"}
            className="w-100 px-0"
            alt="gecsol-principal"
          />
          <div className="row align-items-end text-white barContainer px-0 gx-0">
            <p className="titleTxt px-5">SERVICIOS DE EXPERTOS</p>
            <p className="subtitleHeader fw-semibold px-5 w-75">
              Creamos oportunidades para que su negocio no se detenga
            </p>

            <div className="barGec row align-items-center text-center w-100 gx-0">
              <div className="col-5 opacity-100 my-0">
                <p className="m-0">Líneas de negocio</p>
              </div>
              <div className="col">
                <div class="vr"></div>
              </div>
              <div className="col-6 my-0">
                <p className="m-0">Contáctanos </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimationOnScroll animateIn="animate__fadeInLeft">
        <div className="row justify-content-end imgBussinesContainer marginContainer mx-3">
          <div className="col-4">
            <p className="titleTxt lh-1  michroma ">
              DESCUBRE NUESTRAS LÍNEAS DE NEGOCIO
            </p>
          </div>
          <div className="col-3 imgBussinesContainer text-center">
            <img
              src={URL_DOMAIN + "assets/images/gec/background-1-bussines-gec.png"}
              className="imgBussiness px-0"
              onClick={() => [navigate("/gsoft")]}
              alt="gsoft-banner"
            />
            <img
              src={URL_DOMAIN + "assets/images/gec/logo-1-bussines-gec.png"}
              className="imgBussinesGsoft"
              alt="gsoft-logo"
            />
          </div>
          <div className="col-3 imgBussinesContainer text-center">
            <img
              src={URL_DOMAIN + "assets/images/gec/background-2-bussines-gec.png"}
              className="imgBussiness px-0"
              onClick={() => [navigate("/ggreen")]}
              alt="ggreen-banner"
            />
            <img
              src={URL_DOMAIN + "assets/images/gec/logo-2-bussines-gec.png"}
              className="imgBussinesGgreen"
              alt="ggreen-logo"
            />
          </div>

          <img
            src={URL_DOMAIN + "assets/images/about/background-about.png"}
            className=" imgBackgroundBussines"
          />
        </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="row marginContainer px-3 py-5 mx-3 align-items-center ">
          <div className="col-4">
            {/* <AnimationOnScroll animateIn="animate__rubberBand"> */}
            <p className="titleTxt michroma">
              Obtenga una ventaja competitiva
            </p>
            {/* </AnimationOnScroll> */}
            <p className="raleway">
              Nuestro portafolio incluye las mejores soluciones de outsourcing
              en software de impresión, desarrollo a la medida, línea educativa,
              seguridad perimetral, en general, todo lo que su corporación
              necesita para llevar a cabo las operaciones a través de
              tecnología.
            </p>
            <button
              type="button"
              class="btn btn-primary-gec mt-4 michroma"
              onClick={() => [navigate("/gsoft")]}
            >
              Conoce más
            </button>
          </div>
          <div className="col-8 advantageContainer">
            <img
              src={URL_DOMAIN + "assets/images/gec/background-advantage-gec.png"}
              className="w-100"
              alt="ventajas-gec"
            />
            <div className="row gx-0 advantageComponent">
              <div className="col-6 offset-5">
                <p className="raleway">
                  Somos una tecnologia limpia. Nuestro propósito es ayudar a las
                  empresas a mitigar su huella ambiental mediante un programa de
                  sostenibilidad.
                </p>
                <img
                  src={URL_DOMAIN + "assets/images/gec/component-advantage-gec.png"}
                  className="w-100"
                  alt="ventajas-gec"
                  onClick={() => [navigate("/ggreen")]}
                />
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="row marginContainer mx-3 text-center ">
          <p className="titleTxt mb-5 michroma">Casos de éxito</p>
          <div
            id="carouselControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row align-items-center">
                  <div className="col-3">
                    <img
                      src={URL_DOMAIN + "assets/images/gec/logo-1-company-gec.png"}
                      className="w-50"
                      alt="Policía logo"
                    />
                  </div>
                  <div className="col-3">
                    <img
                      src={URL_DOMAIN + "assets/images/gec/logo-2-company-gec.png"}
                      className="w-50"
                      alt="Banco Agrario Logo"
                    />
                  </div>
                  <div className="col-3">
                    <img
                      src={URL_DOMAIN + "assets/images/gec/logo-3-company-gec.png"}
                      className="w-50"
                      alt="Universidad Externado Logo"
                    />
                  </div>
                  <div className="col-3">
                    <img
                      src={URL_DOMAIN + "assets/images/gec/logo-4-company-gec.png"}
                      className="w-50"
                      alt="Agencia Nacional de Minería Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </AnimationOnScroll>

      {/* HIDDEN */}

      <hr className="d-none" />

      <div className="row my-5 me-3 d-none">
        <div className="col-4">
          <img
            src={URL_DOMAIN + "assets/images/gec/img-quoter-gec.png"}
            className="w-100"
            alt="Cotizador imagen"
          />
        </div>
        <div className="col-8">
          <p className="subtitleTxt fw-bold w-25">Cotiza con nosotros</p>
          <p>
            Facilitamos procesos con nuestros clientes, ahora podrás cotizar o
            contactar con un asesor para que obtengas la mejor información de
            nuestros productos y servicios.
          </p>
          <div className="row text-center mt-3">
            <div className="col-4">
              <button type="button" class="btn btn-primary-gec w-75">
                Click Aquí
              </button>
            </div>
            <div className="col-4">
              <button type="button" class="btn btn-primary-gec w-75">
                Contacta un asesor
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className="mx-4" />

      <div className="row marginContainer text-center justify-content-center mapGecContainer gx-0">
        <div className="col-10">
          <div className="card shadow ">
            <div className="card-body">
              <div className="row align-items-center ">
                <div className="col-3 text-end">
                  <p className="mb-0">Bogotá, Colombia</p>
                  <p className="mb-0">110921</p>
                </div>
                <div className="col-1 text-start">
                  <img
                    src={URL_DOMAIN + "assets/images/contact/icon-1-contact.png"}
                    className="w-25 px-0"
                    alt="icon GPS"
                  />
                </div>
                <div className="col-3 text-end">
                  <p className="mb-0">3005413388</p>
                </div>
                <div className="col-1 text-start">
                  <img
                    src={URL_DOMAIN + "assets/images/contact/icon-2-contact.png"}
                    className="w-25 px-0"
                    alt="Icon phone"
                  />
                </div>
                <div className="col-3 text-end">
                  <p className="mb-0">info@gecsol.co</p>
                </div>
                <div className="col-1 text-start">
                  <img
                    src={URL_DOMAIN + "assets/images/contact/icon-3-contact.png"}
                    className="w-25 px-0"
                    alt="Icon email"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mapGec gx-0">
          <img
            src={URL_DOMAIN + "assets/images/gec/map-gec.png"}
            className="w-100"
          />
        </div>
      </div>
    </>
  );
}

export { HomeUI };
