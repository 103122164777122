import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HomeUI } from './HomeUI'

function Home() {
  const navigate = useNavigate();
  return (
    <HomeUI navigate={navigate}/>
  )
}

export default Home