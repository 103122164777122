import { BrowserRouter } from "react-router-dom";
import Footer from "./components/utils/Footer/Footer";
import { NavBar } from "./components/utils/NavBar/NavBar";
import { Router } from "./routers/Router";
import "./utils/theme.css";
import "animate.css";

function App() {
  return (
    <div className="App container-xxl px-0">
      <BrowserRouter>
        <NavBar />
        <div className="ownContainer">
          <Router />
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
