import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AboutUI } from './AboutUI'

function About() {
  const navigate = useNavigate();
  return (
    <AboutUI navigate={navigate}/>
  )
}

export default About