import "./Contact.css";
import {URL_DOMAIN } from "../../utils/constants";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";
import { ImFacebook } from "react-icons/im";
import { AnimationOnScroll } from "react-animation-on-scroll";

function ContactUI(props) {
  const { formik } = props;

  return (
    <>
      <div className="containerPage w-100 michroma">
        <div className="row w-100 gx-0">
          <img
            src={URL_DOMAIN + "assets/images/contact/img-principal-contact.png"}
            className="w-100 px-0"
          />
          <div className="row align-items-end text-white barContainer px-0 ">
            <div className="barContact row text-center justify-content-end align-items-center w-100 mt-3 gx-0">
              <div className="col-12 opacity-100">
                <p className="m-0">Contáctanos</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimationOnScroll animateIn="animate__fadeIn">
      <div className="row marginContainer contactContainer px-5 gx-0">
        <img
          src={URL_DOMAIN + "assets/images/contact/background-image-1-contact.png"}
          className="backgroundContact1"
          alt="Background"
        />

        <div className="col-4 pt-5 ps-5">
          <img
            src={URL_DOMAIN + "assets/images/contact/logo-gec-contact.png"}
            className="w-50 px-0"
            alt="Logo Gec"
          />
          <div className="row mt-3 py-3 gx-0 align-items-center raleway">
            <div className="col-9 text-end">
              <p className="mb-0">Bogotá, Colombia</p>
              <p className="mb-0">110921</p>
            </div>
            <div className="col-2 text-center">
              <img
                src={URL_DOMAIN + "assets/images/contact/icon-1-contact.png"}
                className="w-25 px-0"
                alt="icon GPS"
              />
            </div>
          </div>
          <div className="row py-3 gx-0 align-items-center raleway">
            <div className="col-9 text-end">
              <p className="mb-0">3005413388</p>
            </div>
            <div className="col-2 text-center">
              <img
                src={URL_DOMAIN + "assets/images/contact/icon-2-contact.png"}
                className="w-25 px-0"
                alt="Icon phone"
              />
            </div>
          </div>
          <div className="row py-3 gx-0 align-items-center raleway">
            <div className="col-9 text-end">
              <p className="mb-0">info@gecsol.co</p>
            </div>
            <div className="col-2 text-center">
              <img
                src={URL_DOMAIN + "assets/images/contact/icon-3-contact.png"}
                className="w-25 px-0"
                alt="Icon email"
              />
            </div>
          </div>
        </div>

        <div className="col-3 mapContainer">
          <img
            src={URL_DOMAIN + "assets/images/contact/map-contact.png"}
            className="w-100 px-0"
            alt="Location Office"
          />
          <img
            src={URL_DOMAIN + "assets/images/contact/background-image-1-contact.png"}
            className="backgroundContact2 px-0"
            alt="Background"
          />
        </div>

        <div className="col-3 ps-5">
          <p>Obtenga más información sobre nuestras soluciones:</p>
          <div className="mb-3">
            <div className="row">
              <div className="col">
                <label htmlFor="nameInput" className="form-text">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control border-0 border-bottom rounded-0"
                  id="nameInput"
                  value={formik.values.name}
                  onChange={(e) => formik.setFieldValue("name", e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="row">
              <div className="col">
                <label htmlFor="companyInput" className="form-text">
                  Compañía
                </label>
                <input
                  type="text"
                  className="form-control border-0 border-bottom rounded-0"
                  id="companyInput"
                  value={formik.values.company}
                  onChange={(e) =>
                    formik.setFieldValue("company", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="row">
              <div className="col">
                <label htmlFor="emailInput" className="form-text">
                  Correo Electrónico
                </label>
                <input
                  type="text"
                  className="form-control border-0 border-bottom rounded-0"
                  id="emailInput"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue("email", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="row">
              <div className="col">
                <label htmlFor="phoneInput" className="form-text">
                  Teléfono
                </label>
                <input
                  type="text"
                  className="form-control border-0 border-bottom rounded-0"
                  id="phoneInput"
                  value={formik.values.phone}
                  onChange={(e) =>
                    formik.setFieldValue("phone", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="row">
              <div className="col">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="¿Cómo podemos ayudarte?"
                  id="detailsInput"
                  value={formik.values.details}
                  onChange={(e) =>
                    formik.setFieldValue("details", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="text-center mb-5">
            <button
              type="submit"
              className="btn btn-primary-contact"
              onClick={formik.handleSubmit}
            >
              Enviar
            </button>
          </div>
          <div className="row align-items-center justify-content-center mt-4">
            <div className="col-1 px-4">
              <a
                href="https://instagram.com/gec_sol?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram size="1.5rem" color="black"/>
              </a>
            </div>
            <div className="col-1 px-4">
              <a
                href="https://www.facebook.com/GECSOLSAS?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImFacebook size="1.5rem" color="black"/>
              </a>
            </div>
            <div className="col-1 px-4">
              <a
                href="https://www.linkedin.com/company/gecsol/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillLinkedin size="1.8rem" color="black"/>
              </a>
            </div>
            <div className="col-1 px-4">
              <a
                href="https://www.youtube.com/@gecsol5471"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsYoutube size="1.8rem" color="black"/>
              </a>
            </div>
          </div>
        </div>
      </div>
      </AnimationOnScroll>
    </>
  );
}

export { ContactUI };
