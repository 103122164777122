import { Route, Routes } from "react-router-dom";
import Home from "../components/Home/Home";
import Gsoft from "../components/Gsoft/Gsoft"
import Ggreen from "../components/Ggreen/Ggreen"
import About from "../components/About/About"
import Blog from "../components/Blog/Blog";
import Contact from "../components/Contact/Contact";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/gsoft" element={<Gsoft />}></Route>
      <Route path="/ggreen" element={<Ggreen />}></Route>
      <Route path="/blog" element={<Blog />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
    </Routes>
  );
}

export { Router };
