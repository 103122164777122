import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GgreenUI } from './GgreenUI'

function Ggreen() {

  const navigate = useNavigate();

  return (
    <GgreenUI navigate={navigate}/>
  )
}

export default Ggreen