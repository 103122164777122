import { AnimationOnScroll } from "react-animation-on-scroll";
import {URL_DOMAIN } from "../../utils/constants";
import "./Ggreen.css";

function GgreenUI(props) {
  const { navigate } = props;
  return (
    <>
      <div className="containerPage w-100 michroma">
        <div className="row w-100 gx-0">
          <img
            src={URL_DOMAIN + "assets/images/ggreen/img-principal-ggreen.png"}
            className="w-100 px-0"
          />
          <div className="row align-items-end text-white barContainer px-0 ">
            <p className="fs-2 fw-bold px-5">COMPENSA</p>
            <div className="barGgreen row text-center justify-content-end align-items-center w-100 mt-3 gx-0">
              <div className="col-5 opacity-100">
                <p className="m-0">Cómo funciona el proyecto?</p>
              </div>
              <div className="col">
                <div className="vr h-100"></div>
              </div>
              <div className="col-6">
                <p className="m-0">Plantío y conseción de arboles </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimationOnScroll animateIn="animate__zoomIn">
        <div className="row marginContainer gx-0">
          <div className="col-6 text-center footprintContainer">
            <img
              src={URL_DOMAIN + "assets/images/ggreen/img-1-footprintCarbon-ggreen.png"}
              className="img1-footprint"
            />
            <img
              src={URL_DOMAIN + "assets/images/ggreen/img-2-footprintCarbon-ggreen.png"}
              className="img2-footprint"
            />
            <img
              src={URL_DOMAIN + "assets/images/ggreen/img-3-footprintCarbon-ggreen.png"}
              className="img3-footprint"
            />
          </div>

          <div className="col-3 offset-1">
            <div className="row align-items-center h-100 gx-0">
              <div className="col-12">
                <p className="fw-semibold fs-5 michroma">
                  Compensamos la huella de carbono emitida en nuestras
                  actividades comerciales, a través del plantío, concesión y
                  cuidado de árboles.
                </p>
                <p className="raleway">
                  De esta manera sumamos esfuerzos para asumir el compromiso que
                  tenemos con la sostenibilidad del medio ambiente y la lucha
                  contra el cámbio climático.
                </p>
                <button type="button" className="btn btn-primary-ggreen michroma">
                  Conoce más
                </button>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeIn">
      <div className="marginContainer projectContainer">
        <div className="row mb-5 gx-0 px-5">
          <div className="col-5">
            <p className="subtitleTxt greenTxt michroma">
              Cómo funciona el proyecto?
            </p>
          </div>
        </div>

        <div className="row text-center px-5 gx-0 align-items-end">
          <div className="col-3">
            <img src={URL_DOMAIN + "assets/images/ggreen/logo-1-project-ggreen.png"} />
          </div>
          <div className="col-3">
            <img src={URL_DOMAIN + "assets/images/ggreen/logo-2-project-ggreen.png"} />
          </div>
          <div className="col-3">
            <img src={URL_DOMAIN + "assets/images/ggreen/logo-3-project-ggreen.png"} />
          </div>
          <div className="col-3">
            <img src={URL_DOMAIN + "assets/images/ggreen/logo-4-project-ggreen.png"} />
          </div>
        </div>
        <div className="row text-center px-5 gx-0 ">
          <div className="col-3">
            <p className="grayTxt mt-3 px-3 raleway">
              Calculamos todas las emisiones de CO² emitidas por su compañía, a
              través de nuestras plataformas de cálculo.
            </p>
          </div>
          <div className="col-3">
            <p className="grayTxt mt-3 px-3 raleway">
              Compensamos el CO² a través de la plantacion de arboles
            </p>
          </div>
          <div className="col-3">
            <p className="grayTxt mt-3 px-3 raleway">
              Somos responsables de cada proceso que interviene en la
              supervivencia y desarrollo de los árboles plantados, para que
              cumplan su función de compenzar el CO².
            </p>
          </div>
          <div className="col-3">
            <p className="grayTxt mt-3 px-3 raleway">
              Certificamos tu apoyo a acciones sostenibles y podrás exhibir
              nuestro sello garantizando que contribuyes activamente en
              proyectos que procuran por el medio ambiente.
            </p>
          </div>
        </div>
      </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeIn">
      <div className="row marginContainer gx-0 justify-content-center">
        <img
          src={URL_DOMAIN + "assets/images/ggreen/banner-ggreen2.png"}
          className="imgBannerGgreen"
        />

        <div className="row text-center justify-content-center mt-3">
          <button
            type="button"
            className="btn btn-primary-ggreen w-25"
            onClick={() => [navigate("/contact")]}
          >
            Haz parte del cambio
          </button>
        </div>
      </div>
      </AnimationOnScroll>

      <div className="row mt-2 gx-0">
        <img
          src={URL_DOMAIN + "assets/images/ggreen/background-2-ggreen.png"}
          className="w-100"
        />
      </div>
    </>
  );
}

export { GgreenUI };
