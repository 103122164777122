import React from "react";
import "./NavBar.css";

import { Link, useLocation } from "react-router-dom";
import { URL_DOMAIN } from "../../../utils/constants";

function NavBar() {
  const location = useLocation();
  let logo;

  if (location.pathname === "/gsoft") {
    logo = URL_DOMAIN + "assets/images/gsoft/logo-gsoft.png";
  } else if (location.pathname === "/ggreen") {
    logo = URL_DOMAIN + "assets/images/ggreen/logo-ggreen.png";
  } else {
    logo = URL_DOMAIN + "assets/images/gec/logo-gec.png";
  }

  return (
    <nav className={`navbar navbar-expand-lg `}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img className="logo" src={logo} alt="logo" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 pr-5 me-5">
            <li className="nav-item ">
              <Link className={`nav-link link text-white`} to={"/About"}>
                Nuestra Compañía
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle text-white"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Líneas de Negocio
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/gsoft">
                    Gsoft
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ggreen">
                    Ggreen
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item d-none">
              <Link className={`nav-link link text-white`} to={"/blog"}>
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link link text-white`} to={"/contact"}>
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export { NavBar };
