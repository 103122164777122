import { AnimationOnScroll } from "react-animation-on-scroll";
import {URL_DOMAIN } from "../../utils/constants";
import "./About.css";


function AboutUI(props) {
  const { navigate } = props;
  return (
    <>
      <div className="containerPage mb-5 w-100 michroma">
        <div className="row w-100 gx-0">
          <img
            src={URL_DOMAIN + "assets/images/about/img-principal-about.png"}
            className="w-100 px-0"
          />
          <div className="row align-items-end text-white barContainer px-0 ">
            <p className="fs-2 fw-bold px-5 w-75">
              TECNOLOGÍA TRANSFORMANDO SERVICIOS
            </p>
            <div className="barAbout row text-center align-items-center w-100 mt-3 gx-0">
              <div className="col-5 opacity-100">
                <p className="m-0">Quienes Somos</p>
              </div>
              <div className="col-2">
                <div class="vr h-100"></div>
              </div>
              <div className="col-5">
                <p className="m-0">Nuestros Valores</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="row align-items-center aboutContainer marginContainer gx-0">
          <div className="col-3 offset-2 text-center me-2">
            <img
              src={URL_DOMAIN + "assets/images/about/img-about.png"}
              className="w-100 px-0"
            />
          </div>
          <div className="col-5 px-5">
            <p className="subtitleTxt raleway">
              Somos una compañía que integra servicios a través de tecnología
            </p>
            <p>
              Desarrollamos soluciones y estrategias para transformar tu
              negocio, a la medida de sus necesidades a través de procesos de
              tecnología
            </p>
            <button
              type="button"
              class="btn btn-primary-about"
              onClick={() => [navigate("/gsoft")]}
            >
              Conoce más
            </button>
          </div>
          <div className="im">
            <img
              src={URL_DOMAIN + "assets/images/about/background-about.png"}
              className=" imgBackgroundAbout"
            />
          </div>
        </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeInLeft">
        <div className="row marginContainer gx-0">
          <div className="col-8 offset-2 text-center">
            <p className="fs-3 fw-semibold raleway">
              Todo lo que hacemos nace de una intención genuina y honesta con la
              que buscamos impactar de manera positiva nuestro entorno y el de
              nuestros clientes.
            </p>
          </div>
        </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="marginContainer">
          <div className="row  px-5 gx-0">
            <div className="col-4">
              <p className="subtitleTxt michroma">Nuestros Valores</p>
              <p className="raleway">
                Adoptamos valores que nos permiten continuar entregando
                experiencias memorables.
              </p>
            </div>
          </div>

          <div className="row mt-5 px-5 gx-0 text-center">
            <div className="col-4">
              <img
                src={URL_DOMAIN + "assets/images/about/logo-1-advantage-about.png"}
                className="imgLogoAbout"
              />
            </div>
            <div className="col-4">
              <img
                src={URL_DOMAIN + "assets/images/about/logo-2-advantage-about.png"}
                className=" imgLogoAbout"
              />
            </div>
            <div className="col-4">
              <img
                src={URL_DOMAIN + "assets/images/about/logo-3-advantage-about.png"}
                className=" imgLogoAbout"
              />
            </div>
          </div>

          <div className="row px-5 gx-0 text-center raleway">
            <div className="col-4">
              <p className="pt-3 px-3">INNOVACIÓN</p>
            </div>
            <div className="col-4">
              <p className="pt-3 px-3">SERVICIO</p>
            </div>
            <div className="col-4">
              <p className="pt-3 px-3">CONCIENCIA AMBIENTAL</p>
            </div>
          </div>

          <div className="row px-5 gx-0 raleway">
            <div className="col-4">
              <p className="pt-3 px-3">
                Transformamos procesos a través de la tecnología generando
                impacto social, ambiental y organizacional dentro de las
                compañías.
              </p>
            </div>
            <div className="col-4">
              <p className="pt-3 px-3">
                Nuestros procesos aseguran la prestación de un óptimo servicio a
                nuestros clientes descubriendo nuevos caminos Para el éxito,
                personalizando su entorno a través de la tecnología e Innovando
                para las necesidades del mercado.
              </p>
            </div>
            <div className="col-4">
              <p className="pt-3 px-3">
                Minimizar el impacto de las emisiones de CO2, implementando
                practicas que generan una huella ambiental positiva.
              </p>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <div className="row justify-content-center marginContainer gx-0">
        <div className="col-10">
          <div
            id="carouselControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={URL_DOMAIN + "assets/images/about/banner-1-about.png"}
                  className=" w-100"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={URL_DOMAIN + "assets/images/about/banner-2-about.png"}
                  className=" w-100"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={URL_DOMAIN + "assets/images/about/banner-3-about.png"}
                  className=" w-100"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export { AboutUI };
