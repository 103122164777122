import { AnimationOnScroll } from "react-animation-on-scroll";
import {URL_DOMAIN } from "../../utils/constants";
import "./Gsoft.css";

function GsoftUI(props) {
  const { navigate } = props;
  return (
    <>
      <div className="containerPage michroma">
        <div className="row w-100 gx-0">
          <img
            src={URL_DOMAIN + "assets/images/gsoft/img-principal-gsoft.png"}
            className="w-100 px-0"
          />

          <div className="row align-items-end text-white barContainer px-0 ">
            <p className="fs-2 fw-bold px-5">TRANSFORMACIÓN DIGITAL</p>
            <div className="barGsoft row text-center align-items-center justify-content-end w-100 mt-3 gx-0">
              <div className="col-3 opacity-100">
                <p className="m-0">Outsourcing de software de impresión</p>
              </div>
              <div className="col">
                <div className="vr h-100"></div>
              </div>
              <div className="col-2">
                <p className="m-0">Línea educativa</p>
              </div>
              <div className="col">
                <div class="vr h-100"></div>
              </div>
              <div className="col-3">
                <p className="m-0">
                  Desarrollo y soporte de software a la medida
                </p>
              </div>
              <div className="col">
                <div class="vr h-100"></div>
              </div>
              <div className="col-3">
                <p className="m-0">Seguridad perimetral</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="row justify-content-center my-5 gx-0">
          <div className="col-9">
            <div
              id="carouselControls"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="row w-100 ">
                        <img
                          src={URL_DOMAIN + "assets/images/gsoft/banner-1-gsoft.png"}
                          alt="Background banner 1"
                        />
                        <div className="row banner1_Container">
                          <div className="col-6 ">
                            <p className="subtitleTxt purpleTxt michroma">
                              Outsourcing de software de impresión
                            </p>
                            <p className="grayTxt mt-3 raleway">
                              Contamos con presencia global. Además nuestros
                              productos son la primera y más completa solución
                              en Latinoamérica para tener a mano el control y la
                              visibilidad de todo su entorno de impresión.
                            </p>
                            <button
                              type="button"
                              class="btn btn-primary-gsoft michroma"
                              onClick={() => [navigate("/contact")]}
                            >
                              Contacta un asesor
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="card border-0 ">
                    <div className="card-body ">
                      <div className="row w-100 ">
                        <img
                          src={URL_DOMAIN + "assets/images/gsoft/banner-2-gsoft.png"}
                          alt="Background banner 2"
                        />
                        <div className="row banner2_Container h-75 w-100">
                          <div className="col-10 ">
                            <div className="row text-center align-items-end mb-2">
                              <div className="col-4">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/logo-1-banner-2-gsoft.png"}
                                  alt="Ícono nddPrint MPS"
                                  className="h-25"
                                />
                              </div>
                              <div className="col-4">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/logo-2-banner-2-gsoft.png"}
                                  alt="Ícono nddPrint 360"
                                  className="h-25"
                                />
                              </div>
                              <div className="col-4">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/logo-3-banner-2-gsoft.png"}
                                  alt="ícono nddPrint Ecotoner"
                                  className="h-25"
                                />
                              </div>
                            </div>

                            <div className="row text-center">
                              <div className="col-4">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/label-1-banner-2-gsoft.png"}
                                  alt="Banner nddPrintMPS"
                                  className="w-75"
                                />
                              </div>
                              <div className="col-4">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/label-2-banner-2-gsoft.png"}
                                  alt="Banner nddPrint 360"
                                  className="w-75"
                                />
                              </div>
                              <div className="col-4">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/label-3-banner-2-gsoft.png"}
                                  alt="Banner nddPrint Ecotoner"
                                  className="w-75"
                                />
                              </div>
                            </div>

                            <div className="row text-center raleway">
                              <div className="col-4">
                                <p className="grayTxt mt-3 txt">
                                  Haga una gestión completa y centralizada de
                                  equipos, reduciendo costos de operación,
                                  administrando de manera efectiva la cadena de
                                  consumibles y la logística de su equipo.
                                </p>
                              </div>
                              <div className="col-4">
                                <p className="grayTxt mt-3 txt">
                                  ndd print 360 es una solución de control,
                                  concientización, optimización y disciplina
                                  para entornos de impresión corporativos.
                                </p>
                              </div>
                              <div className="col-4">
                                <p className="grayTxt mt-3 txt">
                                  Ahorro inteligente para distribuidores de
                                  outsourcing de impresión de hasta 20%, sin
                                  perdida de calidad en documentos.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="row w-100 ">
                        <img src={URL_DOMAIN + "assets/images/gsoft/banner-3-gsoft.png"} />
                        <div className="row banner3_Container h-75 w-100">
                          <div className="col-10 ">
                            <div className="row text-center align-items-end mb-2">
                              <div className="col-5">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/logo-1-banner-3-gsoft.png"}
                                  className="h-75"
                                />
                              </div>
                              <div className="col-5">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/logo-2-banner-3-gsoft.png"}
                                  className="h-75"
                                />
                              </div>
                            </div>
                            <div className="row text-center">
                              <div className="col-5">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/label-1-banner-3-gsoft.png"}
                                  className="w-100"
                                />
                              </div>
                              <div className="col-5">
                                <img
                                  src={URL_DOMAIN + "assets/images/gsoft/label-2-banner-3-gsoft.png"}
                                  className="w-50"
                                />
                              </div>
                            </div>
                            <div className="row text-center raleway">
                              <div className="col-5">
                                <p className="grayTxt mt-3 txt">
                                  Función que digitaliza e introduce dichos
                                  documentos en sistemas de gestión
                                  electrónicos, organizandolos de forma
                                  automatizada.
                                </p>
                              </div>
                              <div className="col-5">
                                <p className="grayTxt mt-3 txt">
                                  Herramienta diseñada para satisfacer las
                                  demandas del mercado de outsourcing de
                                  impresión y renting actuales y futuras.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeIn">
      <div className="row align-items-center marginContainer ps-4 gx-0">
        <div className="col-4 offset-1">
          <p className="fs-2 purpleTxt michroma">Línea educativa</p>
          <p className="grayTxt w-100 raleway">
            Plataforma 100% en nube para la gestión sencilla de aprendizaje
            institucional o universidades corporativas
          </p>
          <p className="mt-5 fs-4 fw-semibold michroma">
            ¡Involucrar, desarrollar y formar líderes!
          </p>
        </div>
        <div className="col-7 text-end">
          <img
            src={URL_DOMAIN + "assets/images/gsoft/img-kubo.png"}
            className="imgKubo"
          />
        </div>
      </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <AnimationOnScroll animateIn="animate__fadeIn">
      <div className="row  marginContainer px-5 gx-0">
        <div className="col-3 offset-1 text-center">
          <img
            src={URL_DOMAIN + "assets/images/gsoft/img-development.png"}
            className="w-100"
          />
        </div>
        <div className="col-7  ps-3">
          <p className="subtitleTxt purpleTxt michroma">
            {" "}
            Desarrollo y soporte de software a la medida{" "}
          </p>
          <p className="fs-5 raleway">
            Desarrollo para entornos Móvil (Android) y entorno WEB, construcción
            y gestión de soluciones tecnológicas de información para diferentes
            negocios.
          </p>
          <div className="row text-center mt-5">
            <div className="col-3 ">
              <img src={URL_DOMAIN + "assets/images/gsoft/logo-1-development-gsoft.png"} />
            </div>
            <div className="col-3 ">
              <img src={URL_DOMAIN + "assets/images/gsoft/logo-2-development-gsoft.png"} />
            </div>
            <div className="col-3 ">
              <img src={URL_DOMAIN + "assets/images/gsoft/logo-3-development-gsoft.png"} />
            </div>
            <div className="col-3 ">
              <img src={URL_DOMAIN + "assets/images/gsoft/logo-4-development-gsoft.png"} />
            </div>
          </div>
          <div className="row text-center mt-3 raleway">
            <div className="col-3 ">
              <p className="grayTxt">Diseño personalizado e integral.</p>
            </div>
            <div className="col-3 ">
              <p className="grayTxt">Alta calidad.</p>
            </div>
            <div className="col-3 ">
              <p className="grayTxt">Análisis y asesoramiento.</p>
            </div>
            <div className="col-3 ">
              <p className="grayTxt">Modelos atractivos. </p>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
      </AnimationOnScroll>

      <hr className="mx-4" />

      <div className="row align-items-center marginContainer px-5 gx-0">
        <div className="col-3 pe-3">
          <p className="subtitleTxt purpleTxt michroma">Seguridad Perimetral</p>
          <p className="raleway">
            Contamos con soluciones RFID y biométricas que agregan seguridad,
            innovación y valor a sus proyectos seguros de impresión, control de
            acceso y automatización.
          </p>
          <button
            type="button"
            class="btn btn-primary-gsoft michroma"
            onClick={() => [navigate("/contact")]}
          >
            Contacta un asesor
          </button>
        </div>
        <div className="col-3 securityContainer">
          <img
            src={URL_DOMAIN + "assets/images/gsoft/background-1-security-gsoft.png"}
            className="w-100 "
          />
          <div className="securityContainerLogo text-center text-white px-3 imgShow1">
            <img src={URL_DOMAIN + "assets/images/gsoft/logo-1-security-gsoft.png"} />
            <p className="michroma fs-6">Lector Bio Reader SR DMZ079</p>
            <p className="txtHidden1 raleway">
              {" "}
              Primer lector de huella digital del mundo con doble frecuencia
              RFID: 125kHz e 13,56MHz. Cuenta con capacidad de almacenar 500
              huellas y permite que el usuario haga su login con su huella y/o
              una tarjeta de proximidad. Agrega total seguridad de datos a
              proyectos de control de acceso, autenticación en lineas de
              producción, vending machines, POS, Safe Print y cualquier proyecto
              donde se necesite confidencialidad y agilidad en la verificación y
              autenticación de personas.
            </p>
          </div>
        </div>
        <div className="col-3 securityContainer ">
          <img
            src={URL_DOMAIN + "assets/images/gsoft/background-2-security-gsoft.png"}
            className="w-100"
          />
          <div className="securityContainerLogo text-center text-white px-3 imgShow2">
            <img src={URL_DOMAIN + "assets/images/gsoft/logo-2-security-gsoft.png"} />
            <p className="michroma fs-6">Lector Bio Reader SR DMZ079</p>
            <p className="txtHidden2 raleway">
              {" "}
              Primer lector de huella digital del mundo con doble frecuencia
              RFID: 125kHz e 13,56MHz. Cuenta con capacidad de almacenar 500
              huellas y permite que el usuario haga su login con su huella y/o
              una tarjeta de proximidad. Agrega total seguridad de datos a
              proyectos de control de acceso, autenticación en lineas de
              producción, vending machines, POS, Safe Print y cualquier proyecto
              donde se necesite confidencialidad y agilidad en la verificación y
              autenticación de personas.
            </p>
          </div>
        </div>
        <div className="col-3 securityContainer">
          <img
            src={URL_DOMAIN + "assets/images/gsoft/background-3-security-gsoft.png"}
            className="w-100"
          />
          <div className="securityContainerLogo text-center text-white px-3 imgShow3">
            <img src={URL_DOMAIN + "assets/images/gsoft/logo-3-security-gsoft.png"} />
            <p className="michroma fs-6">Lector Bio Reader SR DMZ079</p>
            <p className="txtHidden3 raleway">
              {" "}
              Primer lector de huella digital del mundo con doble frecuencia
              RFID: 125kHz e 13,56MHz. Cuenta con capacidad de almacenar 500
              huellas y permite que el usuario haga su login con su huella y/o
              una tarjeta de proximidad. Agrega total seguridad de datos a
              proyectos de control de acceso, autenticación en lineas de
              producción, vending machines, POS, Safe Print y cualquier proyecto
              donde se necesite confidencialidad y agilidad en la verificación y
              autenticación de personas.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export { GsoftUI };
