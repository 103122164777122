import { AnimationOnScroll } from "react-animation-on-scroll";
import {URL_DOMAIN } from "../../utils/constants";
import "./Blog.css";

function BlogUI() {
  return (
    <>
      <div className="containerPage w-100">
        <div className="row w-100 gx-0">
          <img
            src={URL_DOMAIN + "assets/images/blog/img-principal-blog.png"}
            className="w-100 px-0"
          />
          <div className="row align-items-end text-white barContainer px-0 michroma">
            <p className="fs-2 fw-bold px-5">MANTENTE ACTUALIZADO</p>
            <div className="barBlog row text-center justify-content-end align-items-center w-100 mt-3 gx-0">
              <div className="col-5 opacity-100">
                <p className="m-0">Blog</p>
              </div>
              <div className="col">
                <div class="vr h-100"></div>
              </div>
              <div className="col-6">
                <p className="m-0">Suscríbete</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="row my-5 gx-0 text-center justify-content-evenly">
          <div className="col-3 pubContainer">
            <img
              src={URL_DOMAIN + "assets/images/blog/img-pub-1-blog.png"}
              className="w-100 "
            />
            <div className="text-start pubText text-white px-2">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <p>Ver más →</p>
            </div>
          </div>
          <div className="col-3 pubContainer">
            <img
              src={URL_DOMAIN + "assets/images/blog/img-pub-2-blog.png"}
              className="w-100 "
            />
            <div className="text-start pubText text-white px-2">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <p>Ver más →</p>
            </div>
          </div>
          <div className="col-3 pubContainer">
            <img
              src={URL_DOMAIN + "assets/images/blog/img-pub-3-blog.png"}
              className="w-100 "
            />
            <div className="text-start pubText text-white px-2">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <p>Ver más →</p>
            </div>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="row my-5 gx-0 text-center justify-content-evenly">
          <div className="col-3 pubContainer">
            <img
              src={URL_DOMAIN + "assets/images/blog/img-pub-1-blog.png"}
              className="w-100 "
            />
            <div className="text-start pubText text-white px-2">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <p>Ver más →</p>
            </div>
          </div>
          <div className="col-3 pubContainer">
            <img
              src={URL_DOMAIN + "assets/images/blog/img-pub-2-blog.png"}
              className="w-100 "
            />
            <div className="text-start pubText text-white px-2">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <p>Ver más →</p>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </>
  );
}

export { BlogUI };
